<template>
  <div class="error-page">
    <div class="error-page__img error-page__img--404"></div>
    <h2 class="error-page__title">Ошибка 404</h2>
    <p class="error-page__desc">
      Кажется что-то пошло не так! Страница, которую вы запрашиваете, не существует.
      Возможно, она устарела, была удалена или был введен неверный адрес в адресной строке.
    </p>
    <v-btn color="black" dark class="radius-root" x-large @click="$router.push('/')">
      Перейти на Главную
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Error404',

  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
